<template>
  <div>
    <a-modal :footer="null" :visible="visibleModal" @cancel="visibleModal = false">
      <div class="display-cover">
        <video style="width:85%; height: 100%;" autoplay playsinline></video>
        <canvas v-show="isCaptured" id="canvas" style="width:85%; height: 100%;"></canvas>
        <!-- <canvas class="d-none"></canvas> -->
        <!-- <img v-else src="@/assets/selfie.jpg" alt=""> -->

        <div class="controls">
          <a-button v-if="!openCamera" @click.prevent="toggleCamera" type="default" shape="round" :icon="isCaptured ? 'camera' : 'poweroff'">{{isCaptured ? 'Ambil Foto lagi' : 'Buka Webcam'}}</a-button>
          <a-button v-else @click.prevent="toggleCapture" type="default" shape="round" icon="camera">Tangkap Sekarang</a-button>
          <!-- <a-button type="primary" shape="circle" icon="upload" size="large" /> -->
        </div>
      </div>
    </a-modal>
    <a-form-model
      class="formPersonal"
      :model="personal"
      ref="ruleForm"
      :rules="rules"
    >
      <header-menu title="Profil Peserta Didik" />
      <a-form-model-item :colon="false" prop="nama">
        <span slot="label">
          Nama&nbsp;
          <a-tooltip
            title="Nama peserta didik sesuai dokumen resmi yang berlaku (Akta atau Ijazah sebelumnya )."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input id="ppdb-nama" size="large" v-model="personal.nama"></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="jenis_kelamin">
        <span slot="label"> Jenis Kelamin&nbsp; </span>
        <a-radio-group id="ppdb-jenis_kelamin" size="large" v-model="personal.jenis_kelamin">
          <a-radio value="Laki-Laki">Laki-laki</a-radio>
          <a-radio value="Perempuan">Perempuan</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="nisn">
        <span slot="label">
          NISN&nbsp;
          <a-tooltip
            title="Nomor Induk Siswa Nasional peserta didik (jika memiliki).Jika belum memiliki, maka wajib dikosongkan."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input id="ppdb-nisn" size="large" v-model="personal.nisn"></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="nik_kitas">
        <span slot="label">
          NIK/No. KITAS (WNA)&nbsp;
          <a-tooltip
            title="Nomor Induk Kependudukan yang tercantum pada Kartu Keluarga, Kartu Identitas Anak, atau KTP (jika sudah memiliki) bagi WNI."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input id="ppdb-nik_kitas" size="large" v-model="personal.nik_kitas"></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false">
        <span slot="label">
          Scan Kartu Keluarga&nbsp;
          <a-tooltip
            title="Bukti Scan Kartu Keluarga (Tidak Wajib)"
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-upload
          :fileList="lampiran.kartu_keluarga"
          name="lampiran"
          id="ppdb-lampiran_kartu_keluarga"
          action=""
          :beforeUpload="file => uploadFile(file, 'kartu_keluarga')"
          :customRequest="() => {}"
          :remove="file => handleRemove(file, 'kartu_keluarga')"
        >
          <a-button size="large" icon="upload">Unggah Gambar/Dokumen</a-button>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="tempat_lahir">
        <span slot="label">
          Tempat Lahir&nbsp;
          <a-tooltip
            title="Tempat lahir peserta didik sesuai dokumen resmi yang berlaku."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input id="ppdb-tempat_lahir" size="large" v-model="personal.tempat_lahir"></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="tanggal_lahir">
        <span slot="label">
          Tanggal Lahir&nbsp;
          <a-tooltip
            title="Tanggal lahir peserta didik sesuai dokumen resmi yang berlaku."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-date-picker
          id="ppdb-tanggal_lahir"
          :disabled-date="disabledDate"
          size="large"
          placeholder="Pilih tanggal"
          v-model="personal.tanggal_lahir"
          format="DD-MM-YYYY"
        />
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="akta_lahir">
        <span slot="label">
          No. Registrasi Akta Lahir&nbsp;
          <a-tooltip
            title="Nomor registrasi Akta Kelahiran. Nomor registrasi yang dimaksud umumnya tercantum pada bagian tengah atas lembar kutipan akta kelahiran"
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input id="ppdb-akta_lahir" size="large" v-model="personal.akta_lahir"></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false">
        <span slot="label">
          Scan Akta Lahir&nbsp;
          <a-tooltip
            title="Bukti Scan Akta Lahir (Tidak Wajib)"
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-upload
          id="ppdb-lampiran_akta_lahir"
          :file-list="lampiran.akta_lahir"
          name="lampiran"
          :before-upload="file => uploadFile(file, 'akta_lahir')"
          :customRequest="() => {}"
          :remove="file => handleRemove(file, 'akta_lahir')"
        >
          <a-button size="large" icon="upload">Unggah Gambar/Dokumen</a-button>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item :colon="false" label="Anak Ke-" prop="anak_ke">
        <a-input-number
          id="ppdb-anak_ke"
          :min="1"
          size="large"
          v-model="personal.anak_ke"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="agama">
        <span slot="label">
          Agama & Kepercayaan&nbsp;
          <a-tooltip
            title="Apabila peserta didik adalah penghayat kepercayaan (misalnya pada daerah tertentu yang masih memiliki penganut kepercayaan), dapat memilih opsi Kepercayaan kpd Tuhan YME."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-select id="ppdb-agama" showSearch size="large" v-model="personal.agama">
          <a-select-option
            class='agama-option'
            v-for="agama in religions"
            :key="agama.key"
            :value="agama.name"
            >{{ agama.title }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="kewarganegaraan">
        <span slot="label">
          Kewarganegaraan&nbsp;
          <a-tooltip title="Kewarganegaraan peserta didik.">
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-radio-group  id="ppdb-kewarganegaraan" size="large" v-model="personal.kewarganegaraan">
          <a-radio value="WNI">Indonesia</a-radio>
          <a-radio value="WNA">WNA</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        v-if="personal.kewarganegaraan === 'WNA'"
        label="Nama Negara"
        prop="nama_negara"
      >
        <a-input id="ppdb-nama_negara" size="large" v-model="personal.nama_negara"></a-input>
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        label="Berkebutuhan Khusus"
        prop="isBerkebutuhanKhusus"
      >
        <a-radio-group size="large" id="ppdb-isBerkebutuhanKhusus" v-model="personal.isBerkebutuhanKhusus">
          <a-radio value="ya">Ya</a-radio>
          <a-radio value="tidak">Tidak</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <!-- <a-form-model-item :colon="false">
        <span slot="label">
          Foto Diri&nbsp;
          <a-tooltip
            title="Ambil Foto atau gunakan foto yang sudah ada (Tidak Wajib)."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-row :gutter="[16, 16]" type="flex" align="middle">
          <a-col :md="4" :lg="3">
            <div style="width: 100px; height: 150px; background-color: #ecececec;">
              <img draggable="false" width="100" style="height: 100%" v-if="base64FotoDiri" :src="base64FotoDiri" />
              <div class="d-flex justify-content-center align-items-center h-100" v-else>Foto Kosong</div>
            </div>
          </a-col>
          <a-col :md="7" :lg="5" :xxl="4">
            <a-upload
              class="upload-diri"
              :fileList="[]"
              name="lampiran"
              accept="image/jpg,image/jpeg,image/png"
              :beforeUpload="file => uploadFile(file, 'foto_diri')"
              :remove="file => handleRemove(file, 'foto_diri')"
            >
              <a-button class="w-100" size="large" icon="upload">Unggah Foto</a-button>
            </a-upload>
          </a-col>
          <a-col :md="6" :sm="24" style="line-height: 20px;">atau <a @click.prevent="visibleModal = true" class="hsn-hover-underline">ambil gambar</a></a-col>
        </a-row>
      </a-form-model-item> -->
      <a-form-model-item
        :colon="false"
        v-if="personal.isBerkebutuhanKhusus === 'ya'"
        prop="berkebutuhan_khusus"
      >
        <span slot="label">
          Jenis Kebutuhan Khusus&nbsp;
          <a-tooltip
            title="Kebutuhan khusus yang disandang oleh peserta didik. Dapat dipilih lebih dari satu."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-select
          size="large"
          mode="multiple"
          id="ppdb-berkebutuhan_khusus"
          v-model="personal.berkebutuhan_khusus"
        >
          <a-select-option
            class='berkebutuhan_khusus-option'
            v-for="kebutuhan in disabledList"
            :key="kebutuhan.key"
            :value="kebutuhan.name"
            >{{ kebutuhan.title }}</a-select-option
          >
        </a-select>
      </a-form-model-item>

      <!-- ALAMAT SISWA -->
      <header-menu title="Alamat Saat Ini Peserta Didik" />
      <a-form-model-item :colon="false" prop="provinsi">
        <span slot="label">
          Provinsi&nbsp;
          <a-tooltip title="Provinsi tempat tinggal peserta didik saat ini.">
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-select
          id="ppdb-provinsi"
          showSearch
          size="large"
          v-model="personal.provinsi"
          @change="fetchLocationApi('cities', personal.provinsi)"
        >
          <a-select-option
            class="provinsi-option"
            v-for="province in ppdb.provinces"
            :key="province.key"
            :value="province.key"
            >{{ province.name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="kota">
        <span slot="label">
          Kota&nbsp;
          <a-tooltip title="Kota tempat tinggal peserta didik saat ini.">
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-select
          id="ppdb-kota"
          showSearch
          :disabled="!personal.provinsi"
          size="large"
          v-model="personal.kota"
          @change="fetchLocationApi('districts', personal.kota)"
        >
          <a-select-option
            class="kota-option"
            v-for="city in ppdb.cities"
            :key="city.key"
            :value="city.key"
            >{{ city.name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="kecamatan">
        <span slot="label">
          Kecamatan&nbsp;
          <a-tooltip title="Kecamatan tempat tinggal peserta didik saat ini.">
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <!-- <a-input size="large" v-model="personal.kecamatan"></a-input> -->
        <a-select
          id="ppdb-kecamatan"
          showSearch
          :disabled="!personal.kota"
          size="large"
          v-model="personal.kecamatan"
          @change="fetchLocationApi('villages', personal.kecamatan)"
        >
          <a-select-option
            class="kecamatan-option"
            v-for="district in ppdb.districts"
            :key="district.key"
            :value="district.key"
            >{{ district.name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="nama_kelurahan">
        <span slot="label">
          Nama Kelurahan/Desa&nbsp;
          <a-tooltip
            title="Nama kelurahan/desa tempat tinggal peserta didik saat ini."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <!-- <a-input size="large" v-model="personal.nama_kelurahan"></a-input> -->
        <a-select
          id="ppdb-kelurahan"
          showSearch
          :disabled="!personal.kecamatan"
          size="large"
          v-model="personal.nama_kelurahan"
        >
          <a-select-option
            class="kelurahan-option"
            v-for="village in ppdb.villages"
            :key="village.key"
            :value="village.name"
            >{{ village.name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="nama_dusun">
        <span slot="label">
          Nama Dusun&nbsp;
          <a-tooltip title="Nama dusun tempat tinggal peserta didik saat ini.">
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input
          id="ppdb-dusun"
          :disabled="!personal.nama_kelurahan"
          size="large"
          v-model="personal.nama_dusun"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="alamat">
        <span slot="label">
          Alamat Jalan dan Blok/Nomor Rumah/dll&nbsp;
          <a-tooltip
            title="Jalur tempat tinggal peserta didik, terdiri atas gang, kompleks, blok, nomor rumah, dan sebagainya selain informasi yang diminta oleh kolom-kolom yang lain pada bagian ini."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input id="ppdb-alamat" size="large" v-model="personal.alamat"></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="rt">
        <span slot="label">
          RT&nbsp;
          <a-tooltip title="Nomor RT tempat tinggal peserta didik saat ini.">
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input-number
          id="ppdb-rt"
          :min="1"
          size="large"
          :max="999"
          v-model="personal.rt"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="rw">
        <span slot="label">
          RW&nbsp;
          <a-tooltip title="Nomor RW tempat tinggal peserta didik saat ini.">
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input-number
          id="ppdb-rw"
          :min="1"
          size="large"
          :max="999"
          v-model="personal.rw"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="kode_pos">
        <span slot="label">
          Kode Pos&nbsp;
          <a-tooltip title="Kode pos tempat tinggal peserta didik saat ini.">
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input id="ppdb-kode_pos" size="large" v-model="personal.kode_pos"></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="tempat_tinggal">
        <span slot="label">
          Tempat Tinggal&nbsp;
          <a-tooltip
            title="Kepemilikan tempat tinggal peserta didik saat ini (yang telah diisikan pada kolom-kolom sebelumnya di atas)."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-select id="ppdb-tempat_tinggal" size="large" v-model="personal.tempat_tinggal">
          <a-select-option
            v-for="tempat in livedWithList"
            class="tempat_tinggal-option"
            :key="tempat.key"
            :value="tempat.name"
            >{{ tempat.title }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="moda_transportasi">
        <span slot="label">
          Moda Transportasi&nbsp;
          <a-tooltip
            title="Jenis transportasi utama atau yang paling sering digunakan peserta didik untuk berangkat ke sekolah."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-select id="ppdb-moda_transportasi" size="large" v-model="personal.moda_transportasi">
          <a-select-option
            class="moda_transportasi-option"
            v-for="moda in transportationModeList"
            :key="moda.key"
            :value="moda.name"
            >{{ moda.title }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <header-menu title="Lainnya" />
      <a-form-model-item :colon="false" prop="nomor_kks">
        <span slot="label">
          Nomor KKS (Kartu Keluarga Sejahtera)&nbsp;
          <a-tooltip
            title="Nomor Kartu Keluarga Sejahtera (jika memiliki). Nomor yang dimaksud adalah 6 digit kode yang tertera pada sisi belakang kiri atas kartu (di bawah lambang Garuda Pancasila)."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input id="ppdb-nomor_kks" size="large" v-model="personal.nomor_kks"></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="kps_pkh">
        <span slot="label">
          Penerima KPS/PKH&nbsp;
          <a-tooltip
            title="Status peserta didik sebagai penerima manfaat KPS (Kartu Perlindungan Sosial)/PKH (Program Keluarga Harapan). Peserta didik dinyatakan sebagai penerima KPS/PKH apabila tercantum di dalam kartu keluarga dengan kepala keluarga pemegang KPS/PKH."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-radio-group id="ppdb-kps_kph" size="large" v-model="personal.kps_pkh">
          <a-radio value="ya">Ya</a-radio>
          <a-radio value="tidak">Tidak</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        v-if="personal.kps_pkh === 'ya'"
        prop="nomor_kps_pkh"
      >
        <span slot="label">
          No. KPS/PKH&nbsp;
          <a-tooltip title="Nomor KPS atau PKH yang masih berlaku.">
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input size="large" id="ppdb-nomor_kps_pkh" v-model="personal.nomor_kps_pkh"></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="kip">
        <span slot="label">
          Penerima KIP (Kartu Indonesia Pintar)&nbsp;
          <a-tooltip
            title="Pilih Ya apabila peserta didik memiliki Kartu Indonesia Pintar (KIP). Pilih Tidak jika tidak memiliki."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-radio-group id="ppdb-kip" size="large" v-model="personal.kip">
          <a-radio value="ya">Ya</a-radio>
          <a-radio value="tidak">Tidak</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <template v-if="personal.kip === 'ya'">
        <a-form-model-item :colon="false" prop="nomor_kip">
          <span slot="label">
            Nomor KIP&nbsp;
            <a-tooltip
              title="Nomor KIP milik peserta didik apabila sebelumnya telah dipilih sebagai penerima KIP. Nomor yang dimaksud adalah 6 digit kode yang tertera pada sisi belakang kanan atas kartu (di bawah lambang toga)."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input size="large" id="ppdb-nomor_kip" v-model="personal.nomor_kip"></a-input>
        </a-form-model-item>
        <a-form-model-item
          :colon="false"
          label="Nama Tertera di KIP"
          prop="nama_kip"
        >
          <a-input size="large" v-model="personal.nama_kip"></a-input>
        </a-form-model-item>
        <a-form-model-item
          :colon="false"
          label="Terima Fisik Kartu (KIP)"
          prop="isTerimaFisikKIP"
        >
          <span slot="label">
            Terima Fisik Kartu (KIP)&nbsp;
            <a-tooltip
              title="Status bahwa peserta didik sudah menerima atau belum menerima Kartu Indonesia Pintar secara fisik."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-radio-group size="large" v-model="personal.isTerimaFisikKIP">
            <a-radio value="ya">Ya</a-radio>
            <a-radio value="tidak">Tidak</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </template>
      <a-form-model-item
        :colon="false"
        v-if="personal.kip === 'tidak'"
        prop="layak_pip"
      >
        <span slot="label">
          Usulan Dari Sekolah (Layak PIP)&nbsp;
          <a-tooltip
            title="Pilih Ya apabila peserta didik layak diajukan sebagai penerima manfaat Program Indonesia Pintar. Pilih Tidak jika tidak memenuhi kriteria. Opsi ini khusus bagi peserta didik yang tidak memiliki KIP. Peserta didik yang memiliki KIP silakan dipilih Tidak."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-radio-group  id="ppdb-layak_pip" size="large" v-model="personal.layak_pip">
          <a-radio value="ya">Ya</a-radio>
          <a-radio value="tidak">Tidak</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        v-if="personal.layak_pip === 'ya'"
        :colon="false"
        prop="alasan_pip"
      >
        <span slot="label">
          Alasan Layak PIP&nbsp;
          <a-tooltip
            title="Alasan utama peserta didik jika layak menerima manfaat PIP."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-select id="ppdb-alasan_pip" size="large" v-model="personal.alasan_pip">
          <a-select-option
            class="alasan_pip-option"
            v-for="alasan in alasanLayakPIPLists"
            :key="alasan.key"
            :value="alasan.name"
            >{{ alasan.title }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { religions, disabledList, livedWithList, transportationModeList, alasanLayakPIPLists } from '@/helpers/listsPpdbSelect'
import moment from 'moment'
import { mapState } from 'vuex'
const HeaderMenu = () => import('@/components/app/Registration/Header')

const rules = {
  nama: [
    { required: true, message: 'Nama wajib diisi!', trigger: 'change' },
    { max: 32, message: 'Nama maksimum 32 huruf!', trigger: 'blur' },
  ],
  jenis_kelamin: [
    { required: true, message: 'Jenis kelamin wajib diisi!', trigger: 'change' },
  ],
  nisn: [
    { len: 10, message: 'NISN berformat 10 angka!', trigger: 'blur' },
    {
      validator: (rule, value, callback) => {
        if (isNaN(value)) {
          callback(new Error('Masukkan NISN dengan format angka!'))
        } else {
          callback()
        }
      },
    },
  ],
  nik_kitas: [
    { required: true, message: 'NIK/No. KITAS(WNA) wajib diisi!', trigger: 'change' },
    { max: 16, message: 'NIK/No. KITAS(WNA) maksimum berformat 16 angka!', trigger: 'blur' },
  ],
  tempat_lahir: [
    { required: true, message: 'Tempat lahir wajib diisi!', trigger: 'change' },
    { max: 31, message: 'Tempat lahir maksimum 31 huruf!', trigger: 'blur' },
  ],
  tanggal_lahir: [
    { required: true, message: 'Tanggal lahir wajib diisi!', trigger: 'change' },
  ],
  akta_lahir: [
    { required: true, message: 'Akta lahir wajib diisi!', trigger: 'change' },
    { max: 31, message: 'Akta lahir maksimum 31 karakter!', trigger: 'blur' },
  ],
  agama: [
    { required: true, message: 'Agama wajib diisi!', trigger: 'change' },
  ],
  kewarganegaraan: [
    { required: true, message: 'Kewarganegaraan wajib diisi!', trigger: 'change' },
  ],
  alamat: [
    { required: true, message: 'Alamat wajib diisi!', trigger: 'change' },
    { max: 31, message: 'Alamat maksimum 31 huruf!', trigger: 'blur' },
  ],
  rt: [
    { required: true, message: 'RT wajib diisi!', trigger: 'change' },
    // { max: 3, message: 'RT maksimum 3 huruf!', trigger: 'change' },
  ],
  rw: [
    { required: true, message: 'RW wajib diisi!', trigger: 'change' },
    // { max: 3, message: 'RW maksimum 3 huruf!', trigger: 'change' },
  ],
  nama_dusun: [
    { max: 31, message: 'Dusun maksimum 31 huruf!', trigger: 'blur' },
  ],
  nama_kelurahan: [
    { required: true, message: 'Nama Kelurahan/Desa wajib diisi!', trigger: 'change' },
    // { max: 31, message: 'Kelurahan/Desa maksimum 31 huruf!', trigger: 'blur' },
  ],
  kecamatan: [
    { required: true, message: 'Kecamatan wajib diisi!', trigger: 'change' },
    // { max: 31, message: 'Kecamatan maksimum 31 huruf!', trigger: 'blur' },
  ],
  provinsi: [
    { required: true, message: 'Provinsi wajib diisi!', trigger: 'change' },
  ],
  kota: [
    { required: true, message: 'Kota wajib diisi!', trigger: 'change' },
  ],
  kode_pos: [
    { required: true, message: 'Kode Pos wajib diisi!', trigger: 'change' },
    { max: 5, message: 'Kode Pos maksimum 5 angka!', trigger: 'blur' },
  ],
  tempat_tinggal: [
    { required: true, message: 'Tempat Tinggal wajib diisi!', trigger: 'change' },
  ],
  moda_transportasi: [
    { required: true, message: 'Moda Transportasi wajib diisi!', trigger: 'change' },
  ],
  anak_ke: [
    { required: true, message: 'Anak Keberapa wajib diisi!', trigger: 'change' },
  ],
  nomor_kps_pkh: [
    { required: true, message: 'No. KPS/PKH wajib diisi!', trigger: 'change' },
  ],
  alasan_pip: [
    { required: true, message: 'Alasan Layak PIP wajib diisi!', trigger: 'change' },
  ],
  nama_negara: [
    { required: true, message: 'Nama Negara untuk WNA wajib diisi!', trigger: 'change' },
  ],
  kps_pkh: [
    { required: true, message: 'Penerima KPS/PKH wajib diisi!', trigger: 'change' },
  ],
  kip: [
    { required: true, message: 'Penerima KIP wajib diisi!', trigger: 'change' },
  ],
  nomor_kip: [
    { required: true, message: 'Nomor KIP wajib diisi!', trigger: 'change' },
  ],
  layak_pip: [
    { required: true, message: 'Usulan Sekolah (Layak PIP) wajib diisi!', trigger: 'change' },
  ],
  nama_kip: [
    { required: true, message: 'Nama Tertera di KIP wajib diisi!', trigger: 'change' },
  ],
  isTerimaFisikKIP: [
    { required: true, message: 'Terima Fisik Kartu (KIP) wajib diisi!', trigger: 'change' },
  ],
  isBerkebutuhanKhusus: [
    { required: true, message: 'Berkebutuhan Khusus wajib diisi!', trigger: 'change' },
  ],
  berkebutuhan_khusus: [
    { type: 'array', min: 1, required: true, message: 'Bila ya, jenis kebutuhan khusus wajib diisi!', trigger: 'change' },
  ],
}

// function getBase64 (img) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.addEventListener('load', () => resolve(reader.result))
//     reader.addEventListener('error', (err) => reject(err))
//     reader.readAsDataURL(img)
//   })
// }

export default {
  components: { HeaderMenu },
  data() {
    return {
      personal: {
        nama: null,
        jenis_kelamin: null,
        nisn: null,
        nik: null,
        kewarganegaraan: null,
        berkebutuhan_khusus: [],
        isBerkebutuhanKhusus: null,
        layak_pip: null,
        alasan_pip: null,
        nama_kelurahan: null,
        kecamatan: null,
        kota: null,
      },
      rules,
      religions,
      disabledList,
      livedWithList,
      transportationModeList,
      alasanLayakPIPLists,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      locationLists: {
        provinces: [],
        cities: [],
        districts: [],
        villages: [],
      },
      visibleModal: false,
      openCamera: false,
      isCaptured: false,
    }
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      }
    },
    ...mapState(['ppdb']),
  },
  props: ['isClickAction', 'data', 'lampiran', 'base64FotoDiri'],
  watch: {
    'isClickAction.next'(newVal) {
      if (newVal) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$emit('actionValidate', { action: 'next', data: { pribadi: this.personal } })
          } else {
            this.$emit('actionValidate', false)
            this.$notification.error({
              message: 'Maaf',
              description: 'Ada data wajib yang belum terisi/sesuai. Periksa kembali.',
            })
            return false
          }
        })
      }
    },
    'isClickAction.previous'(newVal) {
      if (newVal) {
        this.$emit('actionValidate', { action: 'previous', data: { pribadi: this.personal } })
      }
    },
    'personal.kip'(newVal) {
      // console.log(newVal)
      if (newVal === 'ya') {
        this.personal.layak_pip = null
        this.personal.alasan_pip = null
      }
    },
    visibleModal(newVal) {
      if (!newVal) {
        const canvas = document.getElementById('canvas')
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height)
        const video = document.querySelector('video')
        const stream = video.srcObject
        this.openCamera = false
        video.pause()
        video.src = false
        if (stream) {
          const tracks = stream.getTracks()
          tracks[0].stop()
        }
      }
    },
  },
  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day')
    },
    async fetchLocationApi(region = 'provinces', model) {
      try {
        if (region !== 'provinces' && !model) return false
        const regionAction = region.toUpperCase()
        const payload = { current: 0 }
        if (model) payload.id = model.split('_')[0]
        await this.$store.dispatch(`ppdb/FETCH_${regionAction}`, payload)
        // this.locationLists[region] = data.map(dat => {
        //   return {
        //     key: dat.id + '_' + dat.nama,
        //     name: dat.nama,
        //   }
        // })

        this.personal.nama_kelurahan = null
        if (region !== 'villages') {
          this.personal.kecamatan = null
          if (region === 'cities') {
            this.personal.kota = null
          }
        }

        return new Promise((resolve) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async uploadFile(file, type) {
      try {
        if (type === 'foto_diri') {
          const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
          if (!isJpgOrPng) {
            this.$notification.error({
              message: 'GAGAL MENGUNGGAH',
              description: 'Hanya menerima foto JPG atau PNG saja.',
            })
          } else this.$emit('addLampiran', { file, type })

          // const loadImage = (url) => new Promise((resolve, reject) => {
          //   const img = new Image()
          //   img.addEventListener('load', () => resolve(img))
          //   img.addEventListener('error', (err) => reject(err))
          //   console.log(url)
          //   img.src = url
          //   // console.log(img)
          // })

          // const base64Img = await getBase64(file)
          // const img = await loadImage(base64Img)
          // const dimension = {
          //   width: img.width,
          //   height: img.height,
          // }

          // const isRatioFit = Math.round(dimension.width / dimension.height) === Math.round(2 / 3)
          // if (!isRatioFit) {
          //   this.$notification.error({
          //     message: 'GAGAL MENGUNGGAH',
          //     description: 'Anda hanya dapat mengunggah file dengan ukuran gambar 4x6 atau kelipatannya.',
          //   })
          // }
        } else {
          this.$emit('addLampiran', { file, type })
        }
        console.log('baerhais')
        return false
      } catch (err) {
        console.log('tess')
        console.log(err)
        this.$notification.error({
          message: 'GAGAL MENGUNGGAH',
          description: 'Terjadi error dalam mengunggah, silahkan coba lagi.',
        })
        return false
      }
    },
    handleRemove(file, type) {
      this.$emit('removeLampiran', { file, type })
    },
    async toggleCamera() {
      // this.photoData = null
      this.isCaptured = false
      const canvas = document.getElementById('canvas')
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      this.openCamera = !this.openCamera
      const constraints = {
        audio: false,
        video: true,
      }

      window.constraints = constraints

      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      const video = document.querySelector('video')
      // const videoTracks = stream.getVideoTracks()
      window.stream = stream // make variable available to browser console
      video.srcObject = stream
    },
    async toggleCapture() {
      this.isCaptured = true
      const video = document.querySelector('video')
      const canvas = document.getElementById('canvas')
      // const photo = document.getElementById('photo');
      const context = canvas.getContext('2d')
      const { width, height } = canvas
      canvas.width = width * 2
      canvas.height = height * 2
      context.drawImage(video, 0, 0, canvas.width, canvas.height)
      const data = canvas.toDataURL('image/png')
      console.log(data)
      context.clearRect(0, 0, canvas.width, canvas.height)
      canvas.width = width
      canvas.height = height
      context.drawImage(video, 0, 0, width, height)
      this.openCamera = false
      // console.log(this.photoData)

      video.pause()
      video.src = false
      const stream = video.srcObject
      const tracks = stream.getTracks()
      tracks[0].stop()
      // const videoTracks = stream.getVideoTracks()
    },
  },
  async created() {
    this.personal = {
      ...this.personal,
      ...this.data,
    }
    console.log(this.personal)
    const { provinces } = this.ppdb
    if (!provinces.length) await this.fetchLocationApi()
  },
}
</script>

<style lang="scss">
.formPersonal {
  .upload-diri {
    .ant-upload {
      width: 100%;
    }
  }

}
.display-cover {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 300px;
  width: 100%;
  // margin: 5% auto;
  position: relative;

  video {
    //margin: 5% auto;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
  }
  img {
    //margin: 5% auto;
    width: 100%;
    height: 100%;
  }

  .controls {
    position: absolute;
    margin-bottom: 20px;
  }

  canvas {
    position: absolute
  }
}
</style>
